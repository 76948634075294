import './assets/App.css';
import Navigation from './components/Navigation';
import WhyUs from './WhyUs';
import Hero from './Hero';
import Process from './Process';
import Benefits from './components/Benefits';
import Services from './Services';
import FAQ from './FAQ';
import Footer from './Footer';

function App() {
  return (
    <>
    <Navigation />
    <Hero />
    <WhyUs />
    <Process />
    <Benefits />
    <Services />
    <FAQ />
    <Footer />
    </>
  );
}

export default App;
