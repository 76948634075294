import React,{useEffect,useRef} from "react";
import "./assets/Services.css";
import icon from "./assets/imgs/icon1.svg"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Services = ()=>{
    const tag = useRef(null);
    const Title = useRef(null);
    const button = useRef(null);
    const s1 = useRef(null);
    const s2 = useRef(null);
    const s3 = useRef(null);
    const s4 = useRef(null);
    const s5 = useRef(null);
    const s6 = useRef(null);
    const s7 = useRef(null);
    const s8 = useRef(null);
    const s9 = useRef(null);
    const s10 = useRef(null);
    const s11 = useRef(null);
    const s12 = useRef(null);
    const s13 = useRef(null);
    const s14 = useRef(null);


    useEffect(() => {
        // Set initial states
        gsap.set(Title.current, { opacity: 0, y: 100 });
        gsap.set(button.current, { opacity: 0, y: 100 });
        gsap.set([s1.current, s2.current, s3.current, s4.current, s5.current, s6.current, s7.current], { opacity: 0, scale: 0 });
        gsap.set([s8.current, s9.current, s10.current, s11.current, s12.current, s13.current, s14.current], { opacity: 0, scale: 0 });
      
        // Title Animation
        gsap.to(Title.current, {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: Title.current,
            start: "top 80%",
            end: "bottom top",
            toggleActions: "play none none reverse",
          },
        });
      
        // Button Animation (Separate ScrollTrigger)
        gsap.to(button.current, {
            opacity: 1,
            y: 0,
            duration: 2, // Slightly longer duration for smoother bounce
            ease: "bounce.out", // Correct bounce ease
            scrollTrigger: {
              trigger: s4.current,
              start: "top 80%", // Trigger when button is 80% in the viewport
              once: true, // Only animate once
            },
          });
      
        // Service animations for S1
        gsap.to([s1.current, s2.current, s3.current, s4.current, s5.current, s6.current, s7.current], {
          opacity: 1,
          scale: 1,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: s4.current,
            start: "top 80%",
            end: "bottom top",
            toggleActions: "play none none reverse",
          },
        });
      
        // Service animations for S3
        gsap.to([s8.current, s9.current, s10.current, s11.current, s12.current, s13.current, s14.current], {
          opacity: 1,
          scale: 1,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: s11.current,
            start: "top 80%",
            end: "bottom top",
            toggleActions: "play none none reverse",
          },
        });
      }, []);
    return(
        <section className="Services" id="Services">
             <div className="Tag" ref={tag}>
                <h6>Services</h6>
            </div>
            <div className="ServicesContainer">
                <div className="S1">
                    <h2 ref={s1}>Naming</h2>
                    <h2 ref={s2}>Brand Book</h2>
                    <h2 ref={s3}>Logo Design</h2>
                    <h2 ref={s4}>Visual Identity</h2>
                    <h2 ref={s5}>Brand Strategy</h2>
                    <h2 ref={s6}>Collateral Design</h2>
                    <h2 ref={s7}>Packaging Design</h2>
                </div>
                <div className="S2">
                <h1 ref={Title}>Your Brand Essentials and Beyond</h1>
                <div ref={button}>
                <button className="HeroButton Sbutton"><img src={icon} alt="Elevate Studio" />Book your Free Consultation</button>
                </div>
                </div>
                <div className="S3">
                <h2 ref={s8}>UI/UX</h2>
                <h2 ref={s9}>Animation</h2>
                <h2 ref={s10}>Web Design</h2>
                <h2 ref={s11}>Social Media</h2>
                <h2 ref={s12}>Video Editing</h2>
                <h2 ref={s13}>Motion Graphic</h2>
                <h2 ref={s14}>Web Development</h2>
                </div>
            </div>
        </section>
    )
}
export default Services;