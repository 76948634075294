import React,{useEffect,useRef} from "react";
import "../assets/Benefits.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Benefits = ()=>{
    
    const Title = useRef(null);
    const s1 = useRef(null);
    const s2 = useRef(null);
    const s3 = useRef(null);
    const s4 = useRef(null);
    const s5 = useRef(null);
    const s6 = useRef(null);
    const s7 = useRef(null);
    const s8 = useRef(null);
    useEffect(()=>{
        gsap.set(Title.current,{opacity:0,y:100});
        gsap.set([s1.current,s3.current,s5.current,s7.current],{opacity:0,x:-100});
        gsap.set([s2.current,s4.current,s6.current,s8.current],{opacity:0,x:-100});

        gsap.to(Title.current,{
            opacity:1,
            y:0,
            duration:1,
            stagger:0.5,
            scrollTrigger:{
                trigger:Title.current,
                start:"top 80%",
                end:"bottom top",
                toggleActions:"play none none reverse",
            },
        })

        
        gsap.to([s1.current,s3.current,s5.current,s7.current],{
            opacity:1,
            x:0,
            duration:1,
            stagger:0.5,
            scrollTrigger:{
                trigger:s3.current,
                start:"top 80%",
                end:"bottom top",
                toggleActions:"play none none reverse",
            },
        })

        
        gsap.to([s2.current,s4.current,s6.current,s8.current],{
            opacity:1,
            x:0,
            duration:1,
            stagger:0.5,
            scrollTrigger:{
                trigger:s3.current,
                start:"top 80%",
                end:"bottom top",
                toggleActions:"play none none reverse",
            },
        })

    })


    return(
        <section className="Benefits" id="Benefits">
            <div className="Tag">
                <h6>Benefits</h6>
            </div>
            <div className="BenefitsTitle" ref={Title}>
                <h1>How Your Brand<br/>Can <span>Elevate Your Success</span></h1>
            </div>
            <div className="Benefitsbanner">
                <div className="BenefitsContainer">
                    <div className="Column1">
                        <h3 ref={s1}>Stand-out in the sea of competitors</h3>
                        <h2 ref={s2}>Create a memorable and recognizable brand that stands out in the crowded market.</h2>
                        <h3 ref={s3}>Drive Revenue With Better Conversions</h3>
                        <h2 ref={s4}>Create a memorable and recognizable brand that stands out in the crowded market.</h2>
                    </div>
                    <div className="Column2">
                    <h3 ref={s5}>Increase Customer Loyalty and Trust</h3>
                        <h2 ref={s6}>Foster long-term relationships and retain customers through a strong, consistent brand</h2>
                        <h3 ref={s7}>Strategically Position Your Brand</h3>
                        <h2 ref={s8}>Develop a brand positioning that meets your target audince expectations, price points, and values.</h2>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Benefits;