import React,{useEffect,useRef} from "react";
import "../assets/App.css";
import Logo from "../assets/imgs/Navlogo.svg"
import gsap from "gsap"

const Navigation = ()=>{
    const nav = useRef(null);

    useEffect(()=>{
        gsap.matchMedia().add("(min-width: 760px",()=>{
            gsap.set(nav.current,{width:0,border:0})

            gsap.to(nav.current,{
                width:"55%",
                border:1,
                ease:"power3.inout",
                duration:2,
            })
        })

        gsap.matchMedia().add("(min-width: 1024px",()=>{
            gsap.set(nav.current,{width:0,border:0})

            gsap.to(nav.current,{
                width:"55%",
                border:1,
                ease:"power3.inout",
                duration:2,
            })
        })
    })

    return(
        <div className="Navigation" ref={nav}>
            <a href="#">
            <img src={Logo} alt="Elevate Studio Logo" />
            </a>
            <a href="#Benefits">Benefits</a>
            <a href="#WhyUs">Why Us</a>
            <a href="#Services">Services</a>
            <a href="#Process">Process</a>
            <a href="#FAQ" id="LastChild">FAQ</a>
        </div>
    )
}

export default Navigation;